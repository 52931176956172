<template>
  <div class="search-dd" :class="{'d-lg-none' : history.product.length + history.search.length + history.brand.length == 0}">
    <input type="search" v-model="localSearch" class="search d-lg-none" placeholder="Search Product..." @keyup="submitSearch" />
    <div class="search-dd-div" v-if="history.product.length">
      <small class="bold">Last Seen</small>
    </div>
    <div class="search-dd-thumb" v-if="history.product.length + history.search.length + history.brand.length > 0">
      <router-link v-for="(product, i) in history.product" :key="`product-${i}`"
        :to="'/product/' + product.slug"
      >
        <b-img-lazy :src="product.image" :alt="product.name" :title="product.name" class="rounded" fluid @error.native="replaceByDefaultImage"></b-img-lazy>
      </router-link>
    </div>
    <div class="search-dd-div" v-if="history.search.length">
      <small class="bold">Last Search</small>
      <small class="text-danger bold" @click="removeHistorySearch()">Clear All</small>
    </div>
    <div v-for="(query, i) in history.search" :key="`search-${i}`" class="search-dd-history">
      <div class="w-100" @click="$emit('search', query)">
        <fawesome-pro variant="far" icon="history" class="text-grey mr-2" size="lg" />{{query}}
      </div>
      <fawesome-pro variant="fal" icon="times" class="text-grey" size="lg" @click.native="removeHistorySearch(i)" />
    </div>
    <div class="search-dd-div" v-if="history.brand.length">
      <small class="bold">Brands</small>
      <small class="text-danger bold" @click="removeHistoryBrand()">Clear All</small>
    </div>
    <div v-for="(brand, i) in history.brand" :key="`brand-${i}`" class="search-dd-brand">
      <router-link class="text-black text-nowrap overflow-hidden w-100" :to="'/shop-by-brands/' + brand.slug">
        <b-img-lazy :src="brand.image" :alt="brand.name" :title="brand.name" fluid height="24"/>
        {{brand.name}}
      </router-link>
      <fawesome-pro variant="fal" icon="times" class="text-grey" @click.native="removeHistoryBrand(i)" />
    </div>
  </div>
</template>
<script>
import { cookieMan } from "@/_helpers";

export default {
  name: "LocalHistory",
  props: ["data"],
  data() {
    return {
      localSearch: null,
      history: this.data
    }
  },
  methods: {
    submitSearch(event) {
      if (event.keyCode === 13) this.$emit("search", this.localSearch);
    },
    removeHistorySearch(index) {
      if (index) this.history.search.splice(index, 1)
      else this.history.search = []
      cookieMan.put("fe_history_search", JSON.stringify(this.history.search))
    },
    removeHistoryBrand(index) {
      if (index) this.history.brand.splice(index, 1)
      else this.history.brand = []
      cookieMan.put("fe_history_brand", JSON.stringify(this.history.brand))
      this.$store.dispatch("setBrandHistory", this.history.brand)
    },
    replaceByDefaultImage(event) {
      event.target.src = require("@/assets/img/default-image.png")
      event.target.classList.add("p-3", "default-image")
    },
  },
  watch: {
    data(newVal) {
      this.history = newVal;
    },
  }
}
</script>
<style scoped>
form.form-search input {
  width: calc(100% - 15px - 1.5rem);
  border-bottom: 1px solid #333;
  margin: 0 2rem;
}
form.form-search input::-webkit-search-cancel-button {
  filter: grayscale(1);
}
</style>
